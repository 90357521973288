import React from 'react';

import { errorBox, loader } from './user-order-affiliate.module.scss';

import { useOrder } from '../../hooks/use-order';
import useTranslations from '../../hooks/use-translations';

import Loader from '../atoms/loader';
import UserOrderHeader from '../molecules/user-order-header';
import UserOrderDetails from './user-order-details';

interface IUserOrderAffiliateProps {
    orderHistoryPage: string;
}

const UserOrderAffiliate: React.FC<IUserOrderAffiliateProps> = ({ orderHistoryPage }) => {
    const orderState = useOrder({ queries: ['affiliate'] });
    const order = orderState.affiliate.data;
    const t = useTranslations('UserOrderAffiliate');

    return (
        <>
            {orderState.affiliate.isLoading && <Loader className={loader} />}
            {!orderState.affiliate.isLoading && (
                <>
                    <UserOrderHeader
                        title={`${t.title} ${order?.uid}`}
                        returnPath={orderHistoryPage}
                    />
                    {orderState.affiliate.isError && (
                        <div className={errorBox}>
                            {orderState.affiliate.errors?.map((error) => {
                                return <p>{error.content}</p>;
                            })}
                        </div>
                    )}
                    {orderState.affiliate.isSuccess && order && (
                        <UserOrderDetails order={order} isAffiliate={true} />
                    )}
                </>
            )}
        </>
    );
};

export default UserOrderAffiliate;
